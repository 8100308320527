//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: $line-height-computed 0;

  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      position: relative;
      float: left; // Collapse white-space
      padding: $padding-base-vertical $padding-base-horizontal;
      line-height: $line-height-base;
      text-decoration: none;
      color: $pagination-color;
      background-color: $pagination-bg;
      border: 1px solid $pagination-border;
      margin-left: 5px;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
        @include border-left-radius($border-radius-base);
        border-radius: 20px 0 0 20px;
        color: darken(#4bb5ff, 12%);
        background-color: #fff;
        border: 2px solid darken(#4bb5ff, 12%);
        cursor: pointer;
        font-weight: bold;
      }
    }
    &:last-child {
      > a,
      > span {
        @include border-right-radius($border-radius-base);
        border-radius: 0 20px 20px 0;
        color: darken(#4bb5ff, 12%);//$pagination-disabled-color;
        background-color: #fff;//$pagination-disabled-bg;
        //border-color: $pagination-disabled-border;
        cursor: $cursor-disabled;
        border: 2px solid darken(#4bb5ff, 12%);
        font-weight: bold;
      }
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      z-index: 2;
      color: darken(#4bb5ff, 12%);
      background-color: #fff;
      border: 2px solid darken(#4bb5ff, 12%);
      cursor: pointer;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      z-index: 3;
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border;
      cursor: default;
    }
  }

  > .disabled {
    > span,
    
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: lighten(#4bb5ff, 12%);//$pagination-disabled-color;
      background-color: #fff;//$pagination-disabled-bg;
      //border-color: $pagination-disabled-border;
      cursor: $cursor-disabled;
      border: 2px solid lighten(#4bb5ff, 12%);
      font-weight: bold;

      &:hover{
        color: lighten(#4bb5ff, 12%);//$pagination-disabled-color;
        background-color: #fff;//$pagination-disabled-bg;
        //border-color: $pagination-disabled-border;
        cursor: $cursor-disabled;
        border: 2px solid lighten(#4bb5ff, 12%);
        font-weight: bold;
      }
    }
  }
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
  @include pagination-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $border-radius-large);
}

// Small
.pagination-sm {
  @include pagination-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-small);
}
